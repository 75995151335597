import { LitElement, css, html } from "lit";
import { property } from "lit/decorators/property.js";
import { unsafeHTML } from "lit/directives/unsafe-html.js";
import { AsyncController } from "../../shared/controllers/async-controller";
import { getProductDetailsPage } from "../../api-sdk/v3/routes";
import { DEFAULT_BASE_URL, SUPPORTED_LANGUAGES, WIDGET_NAMES } from "../../api-sdk/constants";
import { LANGUAGES, TUrl } from "../../api-sdk/types";
import { makeApiKeyHeader } from "../../utils/makeApiKeyHeader";
import { MissingConfig } from "../../utils/beam-errors";
import { enforceConfig } from "../../shared/enforce-config";
import { _cssVariableMap } from "../../shared/fragments/css-variable-map";
import { localizeUserString } from "../../shared/localize";
import { _errorMessage } from "../../shared/fragments/error-message-template";
import { cssResponsiveUtils } from "../../shared/responsive";
import { defineCustomElement } from "../../shared/defineCustomElement";
import { cssReset } from "../../shared/css-reset";
import { defineCustomText, useCustomText } from "../../shared/fragments/custom-text";
import { defineCustomBorder, useCustomBorder } from "../../shared/fragments/custom-border";
// shoelace html tag imports
import "@shoelace-style/shoelace/dist/components/tooltip/tooltip.js";
import { _info } from "../../shared/fragments/info";

type RequiredConfig = {
  apiKey: string;
  storeId: number;
};

const IMAGE_URL_REMOTE_CONFIG_KEY = "--beam-ProductDetailsPage-imageUrl";

export class BeamProductDetailsPage extends LitElement {
  static tagName = "beam-product-details-page";

  @property({ type: String, reflect: true }) public baseUrl: TUrl = DEFAULT_BASE_URL;

  @property({ type: String, reflect: false }) apiKey?: RequiredConfig["apiKey"];

  @property({ type: Number }) storeId?: number;

  @property({ type: String }) public lang: LANGUAGES = "en";

  @property({ type: Boolean }) public draftConfig = false;

  @property({ type: Boolean }) public debug = false;

  get configLang() {
    return SUPPORTED_LANGUAGES[this.lang] || "en";
  }

  getProductDetailsPageData = async () => {
    if (!enforceConfig<RequiredConfig>(["apiKey", "storeId"], this)) throw new MissingConfig();

    const res = await getProductDetailsPage({
      baseUrl: this.baseUrl,
      headers: makeApiKeyHeader(this.apiKey),
      queryParams: {
        version: "1.0.0",
        lang: this.configLang,
        storeId: this.storeId,
        widgetName: WIDGET_NAMES.product_details_page,
        draftConfig: this.draftConfig,
      },
    });
    return res;
  };

  private productDetailsPageDataController = new AsyncController<typeof this.getProductDetailsPageData>(
    this,
    this.getProductDetailsPageData
  );

  async updated(changedProperties: Map<string, unknown>) {
    const requireNewDataProps = ["storeId", "baseUrl", "lang", "apiKey"];
    for (const prop of requireNewDataProps) {
      if (changedProperties.has(prop)) {
        await this.productDetailsPageDataController.exec();
        break;
      }
    }
  }

  renderWidgetIcon() {
    const imageUrl = this.cssVariables[IMAGE_URL_REMOTE_CONFIG_KEY];

    return imageUrl
      ? html`
    <span class="icon-container">
      <img src=${imageUrl} alt="Widget Icon"></img>
    </span>
    `
      : html``;
  }

  render() {
    const { data, loading } = this.productDetailsPageDataController;
    if (loading) {
      return html``;
    }
    if (data == null) {
      if (this.debug) {
        return _errorMessage({ error: new Error("No data") });
      }
      return "";
    }
    return html`
      <style>
        :host {
          ${this.cssVariables.toCSS()}
        }
      </style>
      <div class="root">
        ${this.renderWidgetIcon()}
        <span class="info-container">
          <div class="title-block">
            <h3 class="title">${localizeUserString(this.configLang, data.config?.web?.title)}</h3>
          </div>
          <div class="description-block">
            <span class="description"> ${localizeUserString(this.configLang, data.config?.web?.description)}</span
            >${this.renderDisclosureTooltip()}
          </div>
        </span>
      </div>
    `;
  }

  renderCloseTooltipIcon() {
    return html`<svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.40836 5.40811L1.48828 1.48804M5.40836 5.40811L9.32843 9.32819M5.40836 5.40811L9.32843 1.48804M5.40836 5.40811L1.48828 9.32819"
        stroke="white"
        stroke-width="1.56803"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg> `;
  }

  /* PayPal Giving Fund Disclosure */
  renderDisclosureTooltip() {
    const onClickClose = () => {
      // due to being strictly html, we need to assume any when overriding shoelace properties
      const tooltip = this.renderRoot.querySelector(".ppgf-disclosure-tooltip") as any;
      tooltip.open = false;
    };
    const ppgfDisclosure = this.productDetailsPageDataController.data?.ppgfDisclosure;
    return ppgfDisclosure
      ? html`<sl-tooltip class="ppgf-disclosure-tooltip">
          <div class="ppgf-disclosure-tooltip-content" slot="content">
            <button class="ppgf-disclosure-close" aria-label="Close tooltip" @click="${onClickClose}">
              ${this.renderCloseTooltipIcon()}
            </button>
            <div class="ppgf-disclosure-tooltip-copy">${unsafeHTML(ppgfDisclosure.copy)}</div>
          </div>
          <span class="ppgf-disclosure-tooltip-trigger">
            <div class="ppgf-disclosure-tooltip-trigger-mask d-lg-none"></div>
            ${_info()}
          </span>
        </sl-tooltip>`
      : html``;
  }

  renderDisclosureTooltipHyperlink() {
    const ppgfHyperlink = this.productDetailsPageDataController.data?.ppgfDisclosureHyperlink;
    return ppgfHyperlink
      ? html`<a class="ppgf-disclosure-hyperlink" href="${ppgfHyperlink.url}" target="_blank">${ppgfHyperlink.copy}</a>`
      : html``;
  }
  /* End PayPal Giving Fund Disclosure */

  public get cssVariables() {
    const defaults = {
      "--beam-ProductDetailsPage-imageWidth": "24px",
      "--beam-ProductDetailsPage-imageHeight": "24px",
      "--beam-ProductDetailsPage-maxWidth": "346px",
      "--beam-ProductDetailsPage-paddingTop": "8px",
      "--beam-ProductDetailsPage-paddingRight": "12px",
      "--beam-ProductDetailsPage-paddingBottom": "8px",
      "--beam-ProductDetailsPage-paddingLeft": "15px",
      "--beam-ProductDetailsPage-InfoContainer-marginLeft": "8px",
      "--beam-ProductDetailsPage-backgroundColor": "unset",
      "--beam-ProductDetailsPage-TooltipIcon-width": "10px",
      "--beam-ProductDetailsPage-TooltipIcon-color": "inherit",
      "--beam-ProductDetailsPage-PPGFDisclosure-backgroundColor": "#000000",
      "--beam-ProductDetailsPage-PPGFDisclosure-paddingTop": "10px",
      "--beam-ProductDetailsPage-PPGFDisclosure-paddingRight": "8px",
      "--beam-ProductDetailsPage-PPGFDisclosure-paddingBottom": "10px",
      "--beam-ProductDetailsPage-PPGFDisclosure-paddingLeft": "8px",
      "--beam-ProductDetailsPage-PPGFDisclosureHyperlink-color": "#5CA3FF",
      ...defineCustomText("--beam-ProductDetailsPage-title", {
        fontSize: "12px",
        fontWeight: "bold",
      }),
      "--beam-ProductDetailsPage-title-textAlign": "left",
      ...defineCustomText("--beam-ProductDetailsPage-description", {
        marginTop: "4px",
        fontSize: "12px",
        lineHeight: "15px",
      }),
      "--beam-ProductDetailsPage-description-textAlign": "left",
      ...defineCustomText("--beam-ProductDetailsPage-PPGFDisclosureTooltipContent", {
        fontSize: "12px",
        lineHeight: "16px",
        color: "#FFFFFF",
      }),
      ...defineCustomBorder("--beam-ProductDetailsPage"),
      "--beam-ProductDetailsPage-close-display": "initial",
      "--beam-ProductDetailsPage-close-padding": "0",
    };
    const remoteConfig = this.productDetailsPageDataController?.data?.config?.web?.theme || {};

    const config = { ...defaults, ...remoteConfig };

    const serializable = Object.create({
      toCSS() {
        return _cssVariableMap(this as Record<string, string>);
      },
    });

    return Object.assign(serializable, config);
  }

  static styles = [
    cssReset,
    cssResponsiveUtils,
    css`
      :host {
        font-family: var(--beam-fontFamily);
        font-style: var(--beam-fontStyle);
        font-size: var(--beam-fontSize);
        background-color: var(--beam-backgroundColor);
        color: var(--beam-textColor);
        max-width: var(--beam-ProductDetailsPage-maxWidth);
        word-break: normal;
        display: block;
      }
      .root {
        display: flex;
        align-items: center;
        padding-top: var(--beam-ProductDetailsPage-paddingTop);
        padding-right: var(--beam-ProductDetailsPage-paddingRight);
        padding-bottom: var(--beam-ProductDetailsPage-paddingBottom);
        padding-left: var(--beam-ProductDetailsPage-paddingLeft);
        width: 100%;
        background-color: var(--beam-ProductDetailsPage-backgroundColor);
        ${useCustomBorder("--beam-ProductDetailsPage")}
      }
      .icon-container {
        width: var(--beam-ProductDetailsPage-imageWidth);
        height: var(--beam-ProductDetailsPage-imageHeight);
        flex-shrink: 0;
      }
      .icon-container > img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
      .info-container {
        margin-left: var(--beam-ProductDetailsPage-InfoContainer-marginLeft);
      }
      .title {
        ${useCustomText("--beam-ProductDetailsPage-title")}
      }
      .title-block {
        text-align: var(--beam-ProductDetailsPage-title-textAlign);
        line-height: var(--beam-ProductDetailsPage-title-lineHeight);
      }
      .description {
        ${useCustomText("--beam-ProductDetailsPage-description")}
      }
      .description-block {
        text-align: var(--beam-ProductDetailsPage-description-textAlign);
        line-height: var(--beam-ProductDetailsPage-description-lineHeight);
      }
      .ppgf-disclosure-tooltip {
        --max-width: 268px;
      }
      .ppgf-disclosure-tooltip::part(body) {
        background-color: var(--beam-ProductDetailsPage-PPGFDisclosure-backgroundColor);
        padding-top: var(--beam-ProductDetailsPage-PPGFDisclosure-paddingTop);
        padding-right: var(--beam-ProductDetailsPage-PPGFDisclosure-paddingRight);
        padding-bottom: var(--beam-ProductDetailsPage-PPGFDisclosure-paddingBottom);
        padding-left: var(--beam-ProductDetailsPage-PPGFDisclosure-paddingLeft);
      }
      .ppgf-disclosure-tooltip::part(base__arrow) {
        background-color: var(--beam-ProductDetailsPage-PPGFDisclosure-backgroundColor);
      }
      .ppgf-disclosure-tooltip-content {
        display: flex;
        flex-direction: column;
        ${useCustomText("--beam-ProductDetailsPage-PPGFDisclosureTooltipContent")}
      }
      .ppgf-disclosure-tooltip-copy > a,
      .ppgf-disclosure-close {
        pointer-events: auto;
        color: var(--beam-ProductDetailsPage-PPGFDisclosureHyperlink-color);
      }
      .ppgf-disclosure-close {
        display: var(--beam-ProductDetailsPage-close-display);
        padding: var(--beam-ProductDetailsPage-close-padding);
        height: 12px;
        background: none;
        border: none;
        margin-left: auto;
      }
      .ppgf-disclosure-tooltip-copy > a:visited {
        color: var(--beam-ProductDetailsPage-PPGFDisclosureHyperlink-color);
      }
      .ppgf-disclosure-tooltip-trigger {
        position: relative;
      }
      .ppgf-disclosure-tooltip-trigger-mask {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: calc(var(--beam-ProductDetailsPage-TooltipIcon-width) + 20px);
        height: calc(var(--beam-ProductDetailsPage-TooltipIcon-width) + 20px);
      }
      .ppgf-disclosure-tooltip-trigger > svg {
        width: var(--beam-ProductDetailsPage-TooltipIcon-width);
        color: var(--beam-ProductDetailsPage-TooltipIcon-color);
        height: auto;
        vertical-align: middle;
      }
    `,
  ];
}

defineCustomElement(BeamProductDetailsPage);

declare global {
  interface HTMLElementTagNameMap {
    "beam-product-details-page": BeamProductDetailsPage;
  }
}
